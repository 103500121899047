import React, { useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";
import { Col, Row } from "react-flexbox-grid";
import { getDataSuggest, getIsFieldDisabled } from "./dynamic_suggest_logic";
import _ from "lodash";
import { useDebounce } from "use-debounce";
import { DYNAMIC_SUGGEST_BEBOUNCE_TIME_REQUEST } from "../../../source-config";

const DynamicSuggest = props => {
  const defaultSuggestedValue = _.get(props, 'el.default_value', null);
  const initialSuggestedValue = _.get(props, 'el.value.value', defaultSuggestedValue);
  const doesInitialSuggestedValueWrapInAnArray = _.isObject(initialSuggestedValue) && !(_.isArray(initialSuggestedValue));
  const formattedInitialSuggestedValue = doesInitialSuggestedValueWrapInAnArray ? [initialSuggestedValue] : initialSuggestedValue;
  const [selectedSuggest, setSelectedSuggest] = useState(formattedInitialSuggestedValue);
  const [optionsSuggest, setOptionSuggest] = useState([]);
  const [text, setText] = useState('');
  const [searchQuery] = useDebounce(text, DYNAMIC_SUGGEST_BEBOUNCE_TIME_REQUEST);
  const isTheFirstRender = useRef(true);

  const isCloudIconVisible = Boolean(props.el.is_validable && props.mode === 3 && props.el.observations);
  const isFieldDisabled = getIsFieldDisabled(props.el, props.applicant, props.mode);

  const selectedSuggestHandler = selected => {
    const selectedItem = selected[0];
    const isSelectedItemUndefined = typeof selectedItem === "undefined";

    if (!isSelectedItemUndefined) {
      props.onChangeHandler(selectedItem, props.el.name, props.el.type);
    }

    setSelectedSuggest(selected);
  };

  useEffect(() => {
    if (!isTheFirstRender.current) {
      handleDataSuggest(props.el);
      return
    }

    isTheFirstRender.current = false;

  }, [searchQuery]);

  const handleDataSuggest = async (element) => {
    let { url } = element.extra_attributes;

    const response = await getDataSuggest(url, searchQuery);
    const newOptionSuggest = _.get(response, 'data.data.data', []);
    setOptionSuggest(newOptionSuggest);
  };

  const onTypeaheadKeyDown = (event) => {
    const searchText = event.target.value;
    setText(searchText);
  }

  return (
    <Col
      xs={12}
      md={props.el.width}
    >
      <Row>
        <Col xs={12}>
          <Form.Label className={"font-weight-bold " + (props.el.error ? "text-danger" : "")}>
            {
              Boolean(props.el.requiredSignal) && (
                <span>
                  <b>* </b>
                </span>
              )
            }

            {props.el.label}

            {
              isCloudIconVisible && (
                <span
                  className="observation-input-icon"
                  onClick={() => props.setShow(props.el)}
                >
                  <i
                    className={
                      "far fa-comment-dots" +
                      (props.el.observations ? " text-danger" : "")
                    }
                  />
                </span>
              )
            }
          </Form.Label>

          <Typeahead
            id={props.el.id}
            onChange={selectedSuggestHandler}
            options={optionsSuggest}
            selected={selectedSuggest}
            disabled={isFieldDisabled}
            onKeyDown={onTypeaheadKeyDown}
          />

          <small className="form-text text-muted">
            {props.el.contextual_help ? props.el.contextual_help : ""}
          </small>
        </Col>
      </Row>

      {props.errorSelect}
    </Col>
  );
};

export default DynamicSuggest;
