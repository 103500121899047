import React, { useState, useEffect } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Table, Button, Form } from "react-bootstrap";
import Select from "react-select";
import DatePicker, { registerLocale }  from "react-datepicker";
import moment from "moment";
import Cookies from "js-cookie";
import ObservationModal from "../../dynamic_form/observations/ObservationsModal";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import "./gridField.css";
const uuidv4 = require("uuid/v4");
registerLocale("es", es);


const GridField = props => {
  /**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++**/
  const [requestData, setRequestData] = useState(
    props.el.extra_attributes.data,
  );
  const [requestColumns] = useState(props.el.extra_attributes.columns);
  const [dataGrid, setDataGrid] = useState([]);
  const [columns, setColumns] = useState([]);
  const [activeElementValidable, setActiveElementValidable] = useState({});
  const [observationModal, setObservationModal] = useState(false);
  /**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++**/
  useEffect(() => {
    (async function() {
      await getColumns();
      await setDefaultRow();
    })();
  }, [requestData]);

  /**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++**/

  function onChangeTextBoxHandler(e) {
    let name = e.target.name;
    let cloneArray = [...requestData];
    cloneArray.forEach(element => {
      element.forEach(el => {
        if (el.name === name) {
          el.value = e.target.value;
        }
      });
    });
    setRequestData(cloneArray);
  }
  /**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++**/

  function onChangeSelectHandler(name, selected) {
    let cloneArray = [...requestData];
    cloneArray.forEach(element => {
      element.forEach(el => {
        if (el.name === name) {
          el.value = selected;
        }
      });
    });
    setRequestData(cloneArray);
  }
  /**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++**/

  function onChangeDateHandler(name, selected) {
    let cloneArray = [...requestData];
    cloneArray.forEach(element => {
      element.forEach(el => {
        if (el.name === name) {
          el.value = selected;
        }
      });
    });
    setRequestData(cloneArray);
  }
  /**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++**/

  function onChangeCheckBoxHandler(e) {
    let name = e.target.name;
    let cloneArray = [...requestData];
    cloneArray.forEach(element => {
      element.forEach(el => {
        if (el.name === name) {
          el.value = e.target.checked;
        }
      });
    });
    setRequestData(cloneArray);
  }
  /**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++**/

  async function getColumns() {
    let columns = requestColumns.map((element, index) => {
      return <th className="table-info" key={index}>{element.description}</th>;
    });
    setColumns(columns);
  }

  async function setDefaultRow() {
    let firstElementArray = requestData.map((element, index) => {
      const defaultRow = element.map((el, index) => {
        if (el.type === "select") {
          return <td style={{ width: '15rem'}} key={index}>{setChildElement(el)}</td>;  
        }
        return <td key={index}>{setChildElement(el)}</td>;
      });
      return (
        <tr key={index} id={index}>
          {defaultRow}
        </tr>
      );
    });
    setDataGrid(firstElementArray);
    props.setState({ ...props.state, [props.el.name]: requestData });
  }
  /**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++**/

  async function addNewRow() {
    let cloneData = [...requestData];
    let newRow = requestColumns.map(element => {
      if (element.type === "select") {
        return {
          type: element.type,
          name: uuidv4(),
          options: element.options,
          value: "",
          description: element.description,
        };
      } else if (element.type === "check") {
        return {
          type: element.type,
          name: uuidv4(),
          placeholder: element.placeholder,
          value: false,
          description: element.description,
        };
      } else {
        return {
          type: element.type,
          name: uuidv4(),
          value: "",
          description: element.description,
        };
      }
    });
    let joinArrays = [...cloneData, newRow];
    setRequestData(joinArrays);
    props.setState({ ...props.state, [props.el.name]: joinArrays });
  }
  /**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++**/

  function deleteElementInArray(event) {
    let cloneData = [...requestData];
    let element = event.target.name;
    let rowFinded = 0;
    requestData.map((row, index) => {
      row.forEach(el => {
        if (el.name === element) {
          rowFinded = index;
        }
      });
      return rowFinded;
    });
    cloneData.splice(rowFinded, 1);
    let cloneByClone = [...cloneData];
    setRequestData(cloneByClone);
    props.setState({ ...props.state, [props.el.name]: cloneByClone });
  }
  /**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++**/

  const disableFieldBasedMethod = el => {
    const { userId_02 } = Cookies.get();

    if (!el.readonly || el.readonly === 0) {
      if (props.mode === 2) {
        return true;
      }
      if (props.mode === 1) {
        return false;
      } else {
        if (parseInt(userId_02) !== parseInt(props.applicant)) {
          return true;
        } else {
          if (el.observations) {
            return false;
          } else {
            return true;
          }
        }
      }
    }
    if (el.readonly === 1) {
      if (props.mode === 2) {
        return true;
      }
      if (props.mode === 1) {
        return true;
      } else {
        if (parseInt(userId_02) !== parseInt(props.applicant)) {
          return true;
        } else {
          if (el.observations) {
            return false;
          } else {
            return true;
          }
        }
      }
    }
  };

  const onObservationClickHandler = async el => {
    setActiveElementValidable(el);
    setObservationModal(true);
  };

  function setChildElement(element) {
    switch (element.type) {
      case "text":
        return (
          <Form.Group controlId={`control_${element.name}`}>
            <Form.Control
              type="text"
              disabled={disableFieldBasedMethod(props.el)}
              placeholder={element.placeholder ? element.placeholder : ""}
              name={element.name}
              value={element.value ? element.value : ""}
              onChange={e => onChangeTextBoxHandler(e)}
              style={{ width: "15rem" }}
            />
          </Form.Group>
        );
        // eslint-disable-next-line
        break;
      case "select":
        // eslint-disable-next-line
        const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

        return (
          <Select
            options={element.options}
            name={element.name}
            isDisabled={disableFieldBasedMethod(props.el)}
            menuPortalTarget={document.querySelector("body")}
            placeholder={element.placeholder ? element.placeholder : ""}
            value={
              element.value
                ? element.value
                : {
                    label: "Favor de seleccionar una opcion",
                    value: 0,
                  }
            }
            onChange={selected => onChangeSelectHandler(element.name, selected)}
          />
        );
        // eslint-disable-next-line
        break;
      case "date":
        return (
          <DatePicker
            selected={
              element.value ? moment(element.value).toDate() : new Date()
            }
            onChange={date => onChangeDateHandler(element.name, date)}
            dateFormat="dd-MM-yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            disabled={disableFieldBasedMethod(props.el)}
            dropdownMode="select"
            name={element.name}
            style={{ width: "12rem" }}
            locale="es"
          />
        );
        // eslint-disable-next-line
        break;
      case "check":
        return (
          <div className="form-check">
            <input
              type="checkbox"
              disabled={disableFieldBasedMethod(props.el)}
              className="form-check-input"
              name={element.name}
              checked={element.value ? element.value : false}
              value={element.value ? element.value : false}
              onChange={e => onChangeCheckBoxHandler(e)}
            />
          </div>
        );
        // eslint-disable-next-line
        break;
      case "action":
        return (
          <Button
            disabled={disableFieldBasedMethod(props.el)}
            variant="danger"
            name={element.name}
            onClick={e => deleteElementInArray(e)}
          >
            Eliminar
          </Button>
        );
        // eslint-disable-next-line
        break;

      default:
        break;
    }
  }
  /**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++**/

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col xs={12} style={{ padding: "20px", textAlign: "right" }}>
          <Button 
            disabled={disableFieldBasedMethod(props.el)}
            onClick={addNewRow}
          >
              +
          </Button>
        </Col>
        <Col xs={12}>
          <Form.Label
            className={
              "font-weight-bold " + (props.el.error ? "text-danger" : "")
            }
          >
            <span
              style={
                props.el.requiredSignal
                  ? { display: "inline" }
                  : { display: "none" }
              }
            >
              <b>* </b>
            </span>
            {props.el.label}
            <span
              className="observation-input-icon"
              style={{
                display:
                props.el.is_validable && props.mode === 3
                    ? "inline-block"
                    : "none",
              }}
              onClick={() => onObservationClickHandler(props.el)}
            >
              <i
                className={
                  "far fa-comment-dots" +
                  (props.el.observations ? " text-danger" : "")
                }
              />
            </span>
          </Form.Label>
          <Table
            responsive
            className="table-striped table-sm grid-configuration"
            style={{
              textAlign: "center",
              overflowX: "auto !important",
              display: "block",
              tableLayout: "auto"
            }}
          >
            <thead>
              <tr>{columns}</tr>
            </thead>
            <tbody>{dataGrid}</tbody>
          </Table>
          <small className="form-text text-muted">
            {props.el.contextual_help ? props.el.contextual_help : ""}
          </small>
        </Col>
      </Row>
      <ObservationModal
        show={observationModal}
        setShow={setObservationModal}
        el={activeElementValidable}
        stage={props.stage}
      />
    </>
  );
};

/**++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++**/

export default GridField;
