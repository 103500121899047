import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Col } from "react-flexbox-grid";
import { Button } from "react-bootstrap";
import Cookies from "js-cookie";
import { NotificationManager } from "react-notifications";

const TinyEditor = props => {
  const [text, setText] = useState(props.state[props.el.name]);
  const editorRef = useRef(null);

  useEffect(() => {
    if (props.state[props.el.name] && props.state[props.el.name]['value']) {
      let field = props.state[props.el.name];
      if (typeof field['value'] === 'object') {
        //EXECUTING PROCEDURE
        setText(field['value']['value']);
      } else {
        //CONTINUE PROCEDURE
        setText(field['value']);
      }
    } else {
      const field = props.el;
      const value = field['value'] !== '' ? field['value'] :
        field['extra_attributes']['document_information']['data'];
      setText(value);
      props.onChangeHandler(value, props.el.name, props.el.type);
    }
  }, []);

  const handleEditorChange = e => {
    setText(e.content);
    props.onChangeHandler(e.target.getContent(), props.el.name, props.el.type);
  };

  const getContentText = () => {
    if (editorRef.current) {
      setText(editorRef.current.getContent());
      return editorRef.current.getContent();
    }
  };

  const downloadDocument = async () => {
    const { authentication_token_02 } = Cookies.get();
    const { el, stage } = props;
    const { extra_attributes, id, name } = el;
    const url = `${process.env.REACT_APP_URL_API_LARAVEL}/documents/get_pdf_for_detail/${stage}/${extra_attributes['document_id']}`;
    let data = new FormData();
    data.append('field_id', id);
    data.append('content_file', getContentText());

    await fetch(url, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: authentication_token_02
      }
    }).then(response =>
      response.blob()
        .then(blob => {
          if (blob && blob['type'] && blob['type'] === 'application/pdf') {
            let a = document.createElement('a');
            let url = URL.createObjectURL(blob);
            a.href = url;
            a.download = name + '.pdf';
            a.click();
            setTimeout(() => URL.revokeObjectURL(url), 500);
          } else NotificationManager.error(
            'Ocurrió un error al intentar descargar el documento, inténtalo nuevamente y si el problema persiste contacta al administrador',
            '¡Que mal!',
            5000
          );
        })
        .catch(err => NotificationManager.error(
          'Ocurrió un error al intentar descargar el documento, inténtalo nuevamente y si el problema persiste contacta al administrador',
          '¡Que mal!',
          5000
        )
      )
    );
  };

  return (
    <Col xs={12} className="p-10" style={{ minHeight: "1250px" }}>
      <Button
        onClick={() => downloadDocument()}
        className='mB-10' 
        variant='success'
      >
        Descargar
      </Button>
      <Editor
        onInit={(evt, editor) => editorRef.current = editor}
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        initialValue={text}
        value={text}
        init={{
          plugins: "link image code table",
          toolbar:
            "undo redo | bold italic | alignleft aligncenter alignright | code | table",
          language: "es_MX",
          language_url: "/langs/es_MX.js"
        }}
        onChange={handleEditorChange}
      />
    </Col>
  );
};

export default TinyEditor;
